import { MDXProvider } from "@mdx-js/react"
import { motion } from "framer-motion"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React, { useEffect, useState } from "react"
import Container from "../components/Container"
import Layout from "../components/layout"
import Play, { Pause } from "../components/svg-components"
import Video from "../components/Video"
import "../styles/markdown.sass"
import "../styles/project.scss"
import { Fragment } from "react"
const ImageOverlay = ({ img }) => {
  const styles = {
    display: "flex",
    maxWidth: "90%",
    outline: "2px solid red",
  }
  return (
    <div className="image__overlay">
      <Img fluid={img} alt="" objectFit="center" />
    </div>
  )
}
const item = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      // delay: 0.5,
    },
  },
  hidden: { opacity: 0, y: 10 },
}
const Pill = ({ children }) => {
  const colors = {
    html: "#02AAB0",
    css: "#02B099",
    react: "#028DB0",
    scss: "#46ACB0",
    tailwind: "#319795",
    mongo: "#44A16B",
    node: "#43853D",
    express: "#02B07C",
    firebase: "#FF7700",
    bootstrap: "#7952b3",
    next: "#0070f3",
    styledComponents: "#db7093",
    redis: "#cd5d57",
    pusher: "#8731d2",
    socket: "#555",
    typescript: "#3178c6",
  }
  let color
  if (children.toLowerCase() === "mongo db") {
    color = colors["mongo"]
  } else if (
    children.toLowerCase() === "express.js" ||
    children.toLowerCase() === "express js"
  ) {
    color = colors["express"]
  } else if (children.toLowerCase() === "node js") {
    color = colors["node"]
  } else if (children.toLowerCase() === "tailwind css") {
    color = colors["tailwind"]
  } else if (children.toLowerCase() === "next js") {
    color = colors["next"]
  } else if (children.toLowerCase() === "styled components") {
    color = colors["styledComponents"]
  } else if (children.toLowerCase() === "socket.io") {
    color = colors["socket"]
  } else if (children.toLowerCase() === "firestore") {
    color = colors["firebase"]
  } else if (children.toLowerCase() === "typescript") {
    color = colors["typescript"]
  } else {
    color = colors[children.toLowerCase()]
  }

  return (
    <div
      className="self-center px-3 mt-2 mr-1 text-sm font-normal rounded-md md:self-start sm:text-sm md:text-base tech__pills"
      style={{ background: color || "#02AAB0", color: "white" }}
    >
      {children}
    </div>
  )
}
const PillContainer = children => (
  <div className="flex flex-wrap my-5 pills__container">{children}</div>
)
const shortcodes = { Pill, PillContainer }

const buttonContainer = {
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.11111,
      delay: 0.2,
    },
  },
  hidden: {
    opacity: 0,
  },
}
const ButtonGroup = ({ buttonGroupClass, live, code, inViewport }) => {
  return (
    <motion.div
      className="sticky grid self-start order-3 w-full grid-cols-2 col-start-10 col-end-13 gap-3 md:grid-cols-1 md:order-1 custom-top-10 bottom-2rem"
      style={{ gridTemplateColumns: buttonGroupClass }}
      variants={buttonContainer}
      initial="hidden"
      animate="visible"
    >
      {live && (
        <motion.a
          variants={item}
          href={live}
          style={{ width: "100%" }}
          // onClick={() => onLiveDemoClick(live)}
          className="gradient-primary button cta "
          target="_blank"
          rel="noopener noreferrer"
        >
          Live Demo
        </motion.a>
      )}
      {code && (
        <Fragment>
          {/* <div className="mt-4"></div> */}
          <motion.a
            style={{ width: "100%" }}
            href={code}
            variants={item}
            className={`bg-background-button button`}
            target="_blank"
            rel="noopener noreferrer"
          >
            View Code
          </motion.a>
        </Fragment>
      )}
    </motion.div>
  )
}
// const ButtonGroup = handleViewport(ButtonG)
export default function Project({ data, pageContext }) {
  const { prev, next } = pageContext
  const [playing, setPlaying] = useState("id")
  const { mdx: allMdx } = data
  const [hasRan, setHasRan] = useState(false)
  const [screenSize, setScreenSize] = useState({
    height: 0,
    width: 0,
  })
  const updateScreenSize = () => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight })
  }
  useEffect(() => {
    document.title = allMdx.frontmatter.title + " | Projects" || ""
  }, [allMdx.frontmatter.title])
  useEffect(() => {
    if (!hasRan) {
      setHasRan(true)
      updateScreenSize()
    }
    window.addEventListener("resize", updateScreenSize)
    return () => {
      window.removeEventListener("resize", updateScreenSize)
    }
  }, [screenSize])
  let buttonGroupClass = "1fr"
  const live = allMdx.frontmatter.live_demo
  const code = allMdx.frontmatter.view_code
  const { path } = allMdx.frontmatter
  if (screenSize.width <= 767) {
    if (live && code) {
      buttonGroupClass = "1fr 1fr"
    } else {
      buttonGroupClass = "1fr"
    }
  }
  return (
    <MDXProvider components={shortcodes}>
      <Layout>
        <Container>
          {/* <ImageOverlay img={path.childImageSharp.img} /> */}
          <main className="flex flex-col gap-4 mt-10 md:grid lg:grid-cols-12">
            <div className="col-span-9">
              {path.extension === "mp4" ? (
                <Video
                  src={path.publicURL}
                  playing={playing === path.id}
                  setPlaying={setPlaying}
                  id={path.id}
                >
                  <Play />
                  <Pause />
                </Video>
              ) : (
                <div className="rounded-md">
                  <Img
                    fluid={path.childImageSharp.img}
                    alt=""
                    objectFit="center"
                    className="rounded-md"
                  />
                </div>
              )}
            </div>
            <div className="mb-10 project__content md:mb-0">
              <h2 className="text-2-5xl md:text-3xl lg:text-4xl">
                {allMdx.frontmatter.title}
              </h2>

              <div className="text-xl font-normal text-gray-900 wysiwyg">
                <MDXRenderer>{allMdx.body}</MDXRenderer>
              </div>
            </div>
            <ButtonGroup
              buttonGroupClass={buttonGroupClass}
              live={live}
              code={code}
            />
          </main>
          <div className="flex my-10 md:grid md:grid-cols-12 ">
            <div className="flex justify-between w-full text-xl text-center md:col-span-9 prev-next-box text-text-secondary1">
              {prev === null ? (
                <a
                  className="py-4 text-base text-gray-500 cursor-not-allowed base-md md:text-lg disabled"
                  onClick={() => {}}
                  title="Disabled. No more projects"
                >
                  Prev Project
                </a>
              ) : (
                <Link
                  className="py-4 text-base transition-all duration-200 border border-transparent rounded-md md:text-lg hover:border-text-secondary1 text-text-secondary1 hover:text-text-secondary2"
                  replace
                  to={prev.fields.slug}
                >
                  Prev Project{" "}
                </Link>
              )}
              {next === null ? (
                <a
                  className="py-4 text-base text-gray-500 cursor-not-allowed base-md md:text-lg disabled"
                  onClick={() => {}}
                  title="Disabled. No more projects"
                >
                  Next Project
                </a>
              ) : (
                <Link
                  className="py-4 text-base transition-all duration-200 border border-transparent rounded-md md:text-lg hover:border-text-secondary1 text-text-secondary1 hover:text-text-secondary2"
                  replace
                  to={next.fields.slug}
                >
                  Next Project
                </Link>
              )}
            </div>
          </div>
        </Container>
      </Layout>
    </MDXProvider>
  )
}
export const query = graphql`
  query GET_PROJECT($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        live_demo
        view_code
        path {
          id
          publicURL
          extension
          childImageSharp {
            img: fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
              src
            }
          }
        }
      }
    }
  }
`
